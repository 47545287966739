<template>
  <div class="page" v-if="poll && votes && isVoted != null">
    <navbar :title="poll.title" />

    <template v-if="isVoted">
      <main style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <fa-icon :icon="['far', 'check-circle']" style="font-size: 8rem;" />
        <p class="mt-5 has-text-weight-bold is-size-4" style="margin-bottom: 6rem;">投票を受け付けました</p>
      </main>
    </template>

    <template v-else-if="poll.status === 'close'">
      <main style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <fa-icon :icon="['fas', 'ban']" style="font-size: 8rem;" />
        <p class="mt-5 has-text-weight-bold is-size-4" style="margin-bottom: 6rem;">投票を締め切りました</p>
      </main>
    </template>

    <template v-else-if="poll.status === 'draft'">
      <main style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <fa-icon :icon="['fas', 'award']" style="font-size: 8rem;" />
        <p class="mt-5 has-text-weight-bold is-size-4" style="margin-bottom: 6rem;">投票開始までお待ちください</p>
      </main>
    </template>

    <template v-else-if="!this.currentUser.department">
      <div class="p-5">
        <p>アカウント情報が登録されていないため投票ができません。<br>お手数ですが、AWARD運営担当者までお問い合わせください。</p>
      </div>
    </template>

    <template v-else>
      <main>
        <a
          href="https://shiftinc365.sharepoint.com/sites/SHIFTAWARD2023/"
          target="_blank"
          class="button is-primary is-large is-block"
          style="border-radius: 0;"
        >
          <b>過去の受賞者情報はこちら</b>
          <b-icon icon="chevron-right" class="is-inline-block" />
        </a>

        <div class="award-meta">
          <p class="title is-3 mb-2">{{ awardMeta.title }}</p>
          <p>{{ awardMeta.description }}</p>
        </div>

        <div class="toolbar">
          <div class="level is-mobile">
            <div class="level-left">
              <div class="level-item" style="max-width: 100%;">
                <b-field>
                  <b-select v-model="department" class="department" style="max-width: 50vw;" placeholder="選択してください" :disabled="!isOtherStep">
                    <option v-for="dept in filteredDepartments" :value="dept" :key="dept">{{ dept }}</option>
                  </b-select>
                </b-field>
              </div>
              <div class="level-item">
                <b-field>
                  <b-input v-model="keyword" type="search" icon-pack="fas" icon="search" placeholder="氏名、ふりがなで検索" style="width: 200px; max-width: 30vw;" />
                </b-field>
              </div>
            </div>

            <div class="level-right">
              <div class="level-item">
                <p class="mt-1 ml-3 mr-2"><b>{{ filteredUsers.length }}</b> 名</p>
              </div>
            </div>
          </div>
        </div>
        
        <div class="user-list">
          <template v-if="department">
            <div class="user is-clickable" :class="{ 'is-selected': !vote.candidateId }" @click="selectCcandidate(null)">
              <img :src="require('@/assets/image/na.png')" width="80" height="80">
              <p class="full-name">該当者なし</p>
            </div>
            <div class="user is-clickable" v-for="user in filteredUsers" :class="{ 'is-selected': user.id == vote.candidateId }" :key="user.id" @click="selectCcandidate(user)">
              <img v-lazy="imageUrls[user.id]" width="80" height="80" v-if="imageUrls[user.id]">
              <img :src="require('@/assets/image/avator.png')" width="80" height="80" v-else>
              <p class="full-name">{{ user.fullName }}</p>
              <img :src="require('@/assets/image/badge.png')" class="badge-icon" width="60" height="60" :content="`<div style='text-align: left;'>${user.badges.join('<br>')}</div>`" v-tippy v-if="user.badges && user.badges.length > 0" />
            </div>
          </template>
          <template v-else>
            <p class="is-size-4 has-text-centered" style="padding: 5rem 0 6rem 0; width: 100%;">部門を選択してください</p>
          </template>
        </div>
      </main>

      <div class="comment-container" v-if="vote.candidate">
        <div class="comment">
          <b-field>
            <template #label>
              <p style="overflow-x: auto; white-space: nowrap;">
                <b class="is-size-5">{{ vote.candidate.fullName }}</b> さんを推薦する理由を記入してください (必須)
              </p>
            </template>
            <b-input v-model="vote.comment" type="textarea" rows="2" placeholder="定性的な面だけでなく、定量的な面もできる範囲でご記入ください" custom-class="comment-field" />
          </b-field>
        </div>
      </div>

      <footer>
        <div class="columns is-mobile is-vcentered">
          <div class="column is-narrow" >
            <b-button type="is-white" size="is-medium" @click="prevStep" :disabled="currentStep == 1">戻る</b-button>
          </div>
          <div class="column has-text-centered">
            <div class="step-indicator-list">
              <fa-icon :icon="['fas', 'circle']" class="is-clickable indicator" :class="{ 'is-active': i == currentStep }" v-for="i of awardList.length" :key="i" @click="currentStep = i" :content="awardList[i - 1].title" v-tippy />
            </div>
          </div>
          <div class="column is-narrow">
            <b-button type="is-primary" size="is-medium" style="width: 100px; font-weight: bold;" :loading="isProcessing" @click="submitVote" v-if="isLastStep">投票する</b-button>
            <b-button type="is-primary" size="is-medium" style="width: 100px; font-weight: bold;" :loading="isProcessing" icon-right="chevron-right" @click="submitVote" v-else>次へ</b-button>
          </div>
        </div>
      </footer>
    </template>

    <b-loading v-model="isLoading" is-full-page :can-cancel="false"></b-loading>
  </div>
</template>


<script>
import { db, firebase } from '@/main'
import Navbar from '@/components/navbar'

export default {
  components: { Navbar },
  data() {
    return {
      currentUser: this.$store.state.user,
      pollId: this.$route.params.poll_id,
      poll: {},
      votes: [],
      vote: null,
      isVoted: null,
      awardList: [
        {
          name: "newcomer",
          title: "新人賞",
          description: "慣れない環境の中でも努力し、自身も早いスピードで成長して、際立った成果を出したと思う方を、以下から検索し投票してください。（対象：在籍1年未満の従業員）"
        },
        {
          name: "manager",
          title: "マネージャー賞",
          description: "チームで成果を創出しつつ、メンバーの成長を支援し、マネジメントのロールモデルとなる姿を示したと思う方を、以下から検索し投票してください。（対象：SMG補佐以上の従業員）"
        },
        {
          name: "captain",
          title: "キャプテン賞",
          description: "案件拡大、顧客満足度、メンバーや他キャプテンの意欲向上貢献し、広くアンテナを張って自らオポをつかみ、案件を成長に導く姿勢をもっていると思う方を、以下から検索し投票してください。"
        },
        {
          name: "shifter",
          title: "SHIFTER賞",
          description: "日頃からSHIFTのクレドを体現し、社内外から高い評価を得ている方を、以下から検索し投票してください。（対象：マネージャー賞の対象者を除く従業員）"
        },
        {
          name: "unsung",
          title: "縁の下の力持ち賞",
          description: "あらゆる感謝を集め、縁の下でSHIFTの成長・変化を支えてくれていると思う方を、以下から検索し投票してください。（対象：マネージャー賞の対象者を除く従業員）"
        },
        {
          name: "meister",
          title: "マイスター賞",
          description: "特筆した技能を活かしてプロフェッショナルとしてお客様にサービスを提供し、際立った成果を出したと思う方を、以下から検索し投票してください。（対象：全マイスター）"
        },
        {
          name: "other1",
          title: "他部門賞(1人目)",
          description: "他部門の方で、活躍著しい方、SHIFTクレド体現者、お世話になっている方など、この人こそ受賞するにふさわしいと思う方がいれば、以下から検索し投票してください。（対象：他部門の全従業員）"
        },
        {
          name: "other2",
          title: "他部門賞(2人目)",
          description: "他部門の方で、活躍著しい方、SHIFTクレド体現者、お世話になっている方など、この人こそ受賞するにふさわしいと思う方がいれば、以下から検索し投票してください。（対象：他部門の全従業員）"
        },
        {
          name: "other3",
          title: "他部門賞(3人目)",
          description: "他部門の方で、活躍著しい方、SHIFTクレド体現者、お世話になっている方など、この人こそ受賞するにふさわしいと思う方がいれば、以下から検索し投票してください。（対象：他部門の全従業員）"
        }
      ],
      departments: [
        "営業推進本部",
        "第二事業本部",
        "金融事業部",
        "通信ネットメディア事業部",
        "モビリティ事業部",
        "製造ソリューション事業部",
        "エネルギーインフラ事業部",
        "流通物流事業部",
        "エンターテインメント事業部",
        "基幹・ERP事業部",
        "デリバリ改革統括部",
        "コンサルティング事業部",
        "アジャイル推進部",
        "セキュリティサービス部/AIサービス部",
        "ITソリューション部",
        "ソリューション開発部",
        "人事本部",
        "バックオフィス合同"
      ],
      department: "",
      keyword: "",
      currentStep: 1,
      users: [],
      imageUrls: {},
      isProcessing: false,
      isLoading: false
    }
  },
  firestore() {
    return {
      poll: this.pollRef,
      votes: this.votesRef.where("voterId", "==", this.currentUser.uid)
    }
  },
  mounted() {
    this.checkVoted()
  },
  methods: {
    bindUser() {
      const usersRef = db.collection("users").where("department", "==", this.department).where("isCandidate", "==", true)
      
      switch (this.awardMeta.name) {
        case "newcomer":
          this.$bind("users", usersRef.where("isNewcomer", "==", true))
          break
        case "shifter":
        case "unsung":
          this.$bind("users", usersRef.where("isManager", "==", false))
          break
        case "manager":
          this.$bind("users", usersRef.where("isManager", "==", true))
          break
        case "captain":
          this.$bind("users", usersRef.where("isCaptain", "==", true))
          break
        case "meister":
          this.$bind("users", usersRef.where("isMeister", "==", true))
          break
        case "other1":
        case "other2":
        case "other3":
          this.$bind("users", usersRef)
          break
      }
    },
    selectCcandidate(user) {
      if (user) {
        this.$set(this.vote, "candidateId", user.id)
        this.$set(this.vote, "candidate", user)
      } else {
        this.$delete(this.vote, "candidateId")
        this.$delete(this.vote, "candidate")
      }
    },
    submitVote() {
      if (this.vote.candidateId && (!this.vote.comment || this.vote.comment.length == 0)) {
        this.$toasted.show("推薦理由を入力してください")
        return
      }

      if (this.isLastStep && !confirm("この内容で投票しますか？")) return

      this.isProcessing = true

      if (!this.vote.candidateId) {
        this.vote.comment = ""
      }

      const voterRef = db.collection("users").doc(this.currentUser.uid)
      const params = {
        award: this.awardMeta.name,
        voterRef: voterRef,
        voterId: voterRef.id,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      }

      if (this.vote.candidateId) {
        const candidateRef = db.collection("users").doc(this.vote.candidateId)
        params.candidateRef = candidateRef
        params.candidateId = candidateRef.id
        params.comment = this.vote.comment
      } else if (this.vote.id) {
        params.candidateRef = firebase.firestore.FieldValue.delete()
        params.candidateId = firebase.firestore.FieldValue.delete()
        params.comment = firebase.firestore.FieldValue.delete()
      }

      if (this.vote.id) {
        this.votesRef.doc(this.vote.id).update(params).then(() => {
          this.checkVoted()
          this.nextStep()
        }).finally(() => {
          this.isProcessing = false
        })
      } else {
        this.votesRef.add(params).then(() => {
          this.checkVoted()
          this.nextStep()
        }).finally(() => {
          this.isProcessing = false
        })
      }
    },
    checkVoted() {
      // 最後の賞の投票が完了してるかチェック
      this.votesRef.where("award", "==", this.lastAwardMeta.name).where("voterId", "==", this.currentUser.uid).limit(1).get().then((snap) => {
        this.isVoted = (snap.size == 1)
      })
    },
    prevStep() {
      if (this.currentStep > 1) this.currentStep -= 1
    },
    nextStep() {
      if (!this.isLastStep) this.currentStep += 1
    }
  },
  computed: {
    pollRef() {
      return db.collection("polls").doc(this.pollId)
    },
    votesRef() {
      return this.pollRef.collection("votes")
    },
    awardMeta() {
      return this.awardList[this.currentStep - 1]
    },
    lastAwardMeta() {
      return this.awardList[this.awardList.length - 1]
    },
    isOtherStep() {
      return this.awardMeta.name.startsWith("other")
    },
    isLastStep() {
      return this.currentStep == this.awardList.length
    },
    filteredDepartments() {
      if (this.isOtherStep) {
        return this.departments.filter((d) => d != this.currentUser.department)
      } else {
        return [this.currentUser.department]
      }
    },
    filteredUsers() {
      if (this.keyword && this.keyword.length) {
        return this.users.filter((user) => 
          user.fullName.includes(this.keyword) || user.fullNameKana.includes(this.keyword) 
        )
      } else {
        return this.users
      }
    }
  },
  watch: {
    currentStep: {
      immediate: true,
      handler() {
        this.isLoading = true
        this.department = this.isOtherStep ? null : this.currentUser.department
        this.bindUser()
        this.keyword = ""

        // 投票済みデータ
        this.vote = {}
        this.votesRef.where("award", "==", this.awardMeta.name).where("voterId", "==", this.currentUser.uid).limit(1).get().then((snap) => {  
          if (snap.size == 0) {
            this.isLoading = false
          } else {
            snap.forEach((doc) => {
              const data = doc.data()
              this.vote = {
                id: doc.id,
                candidateId: data.candidateId,
                comment: data.comment,
              }

              if (data.candidateRef) {
                data.candidateRef.get().then((doc) => {
                  this.$set(this.vote, "candidate", doc.data())
                }).finally(() => {
                  this.isLoading = false
                })
              } else {
                this.isLoading = false
              }
            })
          }
        })
      }
    },
    department: {
      immediate: true,
      handler() {
        this.bindUser()
      }
    },
    users: {
      immediate: true,
      handler(to) {
        to.forEach((user) => {
          if (user.fileName) {
            const ref = firebase.storage().ref("images/users/" + user.fileName)
            ref.getDownloadURL().then((url) => {
              this.$set(this.imageUrls, user.id, url)
            })
          }
        })
      }
    }
  },
}
</script>


<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;
}

main {
  flex: 1;
  overflow-y: auto;

  .award-meta {
    padding: 1.5rem;
  }

  .toolbar {
    background-color: #f5f5f5;
    position: sticky;
    top: 0;
    padding: 1rem 1.5rem;
    z-index: 2;
    overflow-x: auto;
    overflow-y: hidden;

    .department ::v-deep .select {
      select[disabled] {
        border-color: #e5e5e5;
        background-color: #e5e5e5;
      }
    }
  }

  .user-list {
    background-color: #f5f5f5;
    border-radius: 3px;
    padding: 1rem 1.25rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .user {
      position: relative;
      display: inline-block;
      background-color: #fff;
      border: 3px solid transparent;
      border-radius: 3px;
      height: 134px;
      width: 100px;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      transition: border-color 0.15s linear;

      &:hover, &.is-selected {
        border-color: #23a7ea;
      }

      img {
        width: 100%;
        height: auto;
      }

      .full-name {
        font-size: 0.85rem;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0.25rem 0.1rem;
        white-space: nowrap;
      }

      .badge-icon {
        color: #f03e3e;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}

.comment-container {
  padding: 1rem 1.5rem;
  height: 150px;
  background-color: #eff5fb;
  border-top: 4px solid #23a7ea;

  .comment {
    ::v-deep {
      .label {
        padding: 0.25rem 0.5rem;
        border-radius: 3px;
      }

      .comment-field {
        resize: none;
      }
    }
  }
}

footer {
  border-top: 1px solid #dbdbdb;
  background-color: #eee;
  min-height: 66px;
  padding: 0.75rem 1.5rem;
  overflow-x: auto;

  .step-indicator-list {
    .indicator {
      color: #ccc;
      padding: 0 0.2rem;

      &.is-active {
        color: #777;
      }
    }
  }
}
</style>
